
import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'
const router = createRouter({
    // history: createWebHashHistory(),  // hash 模式
    history: createWebHistory(),  // history 模式
    routes: [
        {
            path: '/',
            name: 'home',
            component: defineAsyncComponent(() => import(`../views/index/index.vue`)),
            meta: {
                title: '首页',
            },
        }, {
            path: '/recruitment',
            name: 'recruitment',
            component: defineAsyncComponent(() => import(`../views/pages/recruitment.vue`)),
            meta: {
                title: '商家入驻',
            },
        },
    ]
})
// 全局路由守卫
router.beforeEach((to, from, next) => {
    // console.log(to, from)
    if (to.meta.title) {
        document.title = `${to.meta.title}`;
    }
    next()
})
// router.afterEach((to, from) => {
router.afterEach(() => {
    // console.log(to, from)
    // console.log('afterEach')
})
export default router

