import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import router from './router'
// import VueRouter from 'vue-router'

const app = createApp(App)

// app.use(ElementPlus, { size: 'small', zIndex: 3000 })
app.use(ElementPlus, { locale: zhCn, })
app.use(router)
// app.use(VueRouter)

app.mount('#app')
